import {formdataify} from '../service'
export default ({ request }) => ({
  /**
   * post请求
   * @param data
   * @returns {*}
   * @constructor
   */
  RESUME_SAVE (data = {}) {
    // 接口请求
    return request({
      url: '',
      method: 'POST',
      data:formdataify(data)
    })
  },
  /**
   * 获取banner
   * @param data
   * @returns {*}
   * @constructor
   */
  BANNER_GET (params = {}) {
    // 接口请求
    return request({
      url: '/api/Banner/index',
      method: 'GET',
      params
    })
  },
  /**
   * 首页推荐位
   * @param data
   * @returns {*}
   * @constructor
   */
  RECOMMEND_CUSTOM_GET (params = {}) {
    return request({
      url: '/api/Recommend_custom/live_trailer_action',
      method: 'GET',
      params
    })
  },
  /**
   * 首页医学智库
   * @param data
   * @returns {*}
   * @constructor
   */
  INDEX_ARTICLE_GET (params = {}) {
    return request({
      url: '/api/Article',
      method: 'GET',
      params
    })
  },
  /**
   * 医学智库->详情
   * @param data
   * @returns {*}
   * @constructor
   */
  ARTICLE_GET (params = {}) {
    return request({
      url: '/api/Article/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 直播预告
   * @param data
   * @returns {*}
   * @constructor
   */
  LIVETRAILER_GET (params = {}) {
    return request({
      url: '/api/LiveTrailer',
      method: 'GET',
      params
    })
  },
  /**
   * 学会主页
   * @param data
   * @returns {*}
   * @constructor
   */
  LEARN_HOME_GET (params = {}) {
    return request({
      url: '/api/Learn',
      method: 'GET',
      params
    })
  },
  /**
   * 直播预告->详情
   * @param data
   * @returns {*}
   * @constructor
   */
  LIVETRAILER_DETAIL_GET (params = {}) {
    return request({
      url: '/api/LiveTrailer/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 报名
   * @param params
   * @returns {*}
   * @constructor
   */
  CREATE_LIVE_POST (data = {}) {
    return request({
      url: '/api/LiveTrailer/createSign',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 课程列表
   * @param data
   * @returns {*}
   * @constructor
   */
  COURSE_GET (params = {}) {
    return request({
      url: '/api/Course',
      method: 'GET',
      params
    })
  },
  /**
   * 课程->详情
   * @param data
   * @returns {*}
   * @constructor
   */
  COURSE_DETAIL_GET (params = {}) {
    return request({
      url: '/api/Course/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 会议列表
   * @param data
   * @returns {*}
   * @constructor
   */
  MEETING_GET (params = {}) {
    return request({
      url: '/api/Meeting',
      method: 'GET',
      params
    })
  },
  /**
   * 会议->详情
   * @param data
   * @returns {*}
   * @constructor
   */
  MEETTING_DETAIL_GET (params = {}) {
    return request({
      url: '/api/Meeting/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 评论列表
   * @param data
   * @returns {*}
   * @constructor
   */
  LIST_COMMENT_POST (params = {}) {
    return request({
      url: '/api/Comment',
      method: 'GET',
      params
    })
  },
  /**
   * 发布评论
   * @param params
   * @returns {*}
   * @constructor
   */
  CREATE_COMMENT_POST (data = {}) {
    return request({
      url: '/api/Comment/createComment',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 修改昵称
   * @param params
   * @returns {*}
   * @constructor
   */
  UPDATE_USER_POST (data = {}) {
    return request({
      url: '/api/Communal/updateUser',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 点赞 or 收藏
   * @param params
   * @returns {*}
   * @constructor
   */
  CREATE_OPERATION_POST (data = {}) {
    return request({
      url: '/api/Operation/createData',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 收藏列表
   * @param params
   * @returns {*}
   * @constructor
   */
  CREATE_OPERATION_GET (params) {
    return request({
      url: '/api/Operation/index',
      method: 'GET',
      params
    })
  },
  /**
   * 直播订阅
   * @param params
   * @returns {*}
   * @constructor
   */
  LIVE_SIGN_GET (params) {
    return request({
      url: '/api/LiveTrailer/getSign',
      method: 'GET',
      params
    })
  },
  /**
   * 登录
   * @param params
   * @returns {*}
   * @constructor
   */
  LOGIN_POST (data = {}) {
    return request({
      url: '/api/Auth/login',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 获取登录
   * @param params
   * @returns {*}
   * @constructor
   */
  GET_LOGIN_POST (data = {}) {
    return request({
      url: '/api/Auth/getLogin',
      method: 'POST',
      data: formdataify(data)
    })
  },
  /**
   * 科研项目
   * @param data
   * @returns {*}
   * @constructor
   */
  SCIENCE_GET (params = {}) {
    return request({
      url: '/api/Science',
      method: 'GET',
      params
    })
  },
  /**
   * 科研项目详情
   * @param data
   * @returns {*}
   * @constructor
   */
  INFO_SCIENCE_GET (params = {}) {
    return request({
      url: '/api/Science/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 学会活动
   * @param data
   * @returns {*}
   * @constructor
   */
  LEARN_ACTIVITY_GET (params = {}) {
    return request({
      url: '/api/Activity',
      method: 'GET',
      params
    })
  },
  /**
   * 学会活动详情
   * @param data
   * @returns {*}
   * @constructor
   */
  INFO_LEARN_ACTIVITY_GET (params = {}) {
    return request({
      url: '/api/Activity/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 学会动态
   * @param data
   * @returns {*}
   * @constructor
   */
  LEARN_DYNAMIC_GET (params = {}) {
    return request({
      url: '/api/Dynamic',
      method: 'GET',
      params
    })
  },
  /**
   * 学会动态详情
   * @param data
   * @returns {*}
   * @constructor
   */
  INFO_LEARN_DYNAMIC_GET (params = {}) {
    return request({
      url: '/api/Dynamic/detail',
      method: 'GET',
      params
    })
  },
  /**
   * 学会动态分类
   * @param data
   * @returns {*}
   * @constructor
   */
  LEARN_DYNAMIC_TYPE_GET (params = {}) {
    return request({
      url: '/api/Learn/typeList',
      method: 'GET',
      params
    })
  },
  /**
   * 搜索页学会
   * @param data
   * @returns {*}
   * @constructor
   */
  LEARN_SEARCH_GET (params = {}) {
    return request({
      url: '/api/Learn/search',
      method: 'GET',
      params
    })
  },
  /**
   * 直播跳转获取token
   * @param params
   * @returns {*}
   * @constructor
   */
  LIVING_POST (data = {}) {
    return request({
      url: '/api/Communal/getSign',
      method: 'POST',
      data: formdataify(data)
    })
  },
})

