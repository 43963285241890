
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export default [
  {
    // 未匹配到页面
    path: '*',
    redirect: '/index',
  },
  // 首页
  {
    path: '/index', name: 'Index', component: _import('index'),
    meta: { title: '中国医促会妇产分会-蒲公英医学' }
  },
  // 个人中心
  {
    path: '/mine', name: 'Mine', component: _import('mine'),
    meta: { title: '我的' }
  },
  // 个人信息
  {
    path: '/mineInfo', name: 'MineInfo', component: _import('mine/info'),
    meta: { title: '个人信息' }
  },
  // 个人信息修改
  {
    path: '/mineInfoEdit', name: 'MineInfoEdit', component: _import('mine/infoEdit'),
    meta: { title: '个人信息修改' }
  },
  // 收藏夹
  {
    path: '/mineCollect', name: 'MineCollect', component: _import('mine/collect'),
    meta: { title: '收藏夹' }
  },
  // 直播订阅
  {
    path: '/mineLive', name: 'MineLive', component: _import('mine/live'),
    meta: { title: '直播订阅' }
  },
  // 直播
  {
    path: '/live', name: 'Live', component: _import('live'),
    meta: { title: '直播' }
  },
  // 直播详情
  {
    path: '/liveDetail', name: 'LiveDetail', component: _import('live/detail.vue'),
    meta: { title: '直播详情' }
  },
  // 热门课程
  {
    path: '/hotCourse', name: 'HotCourse', component: _import('course/hotCourse.vue'),
    meta: { title: '热门课程' }
  },
  // 课程列表
  {
    path: '/course', name: 'CourseList', component: _import('course/list.vue'),
    meta: { title: '课程列表' }
  },
  // 课程详情
  {
    path: '/courseDetail', name: 'CourseDetail', component: _import('course/detail.vue'),
    meta: { title: '课程详情' }
  },
  // 医学智库
  {
    path: '/medicine', name: 'Medicine', component: _import('medicine'),
    meta: { title: '医学智库' }
  },
  // 医学智库详情
  {
    path: '/medicineDetail', name: 'MedicineDetail', component: _import('medicine/detail.vue'),
    meta: { title: '医学智库详情' }
  },
  // 会议
  {
    path: '/meet', name: 'Meet', component: _import('meet'),
    meta: { title: '会议' }
  },
  // 会议详情
  {
    path: '/meetDetail', name: 'MeetDetail', component: _import('meet/detail.vue'),
    meta: { title: '会议详情' }
  },
  // 学会
  {
    path: '/organ', name: 'Organ', component: _import('organ'),
    meta: { title: '学会' }
  },
  // 学会动态列表
  {
    path: '/trendsList', name: 'TrendsList', component: _import('organ/trendsList.vue'),
    meta: { title: '学会动态' }
  },
  // 学会活动列表
  {
    path: '/scienceList', name: 'ScienceList', component: _import('organ/scienceList.vue'),
    meta: { title: '科研项目' }
  },
  // 科研项目列表
  {
    path: '/activityList', name: 'ActivityList', component: _import('organ/activityList.vue'),
    meta: { title: '学会活动' }
  },
  // 学会活动、详情
  {
    path: '/organDetail', name: 'OrganDetail', component: _import('organ/detail.vue'),
    meta: { title: '学会详情' }
  },
  // 报名
  {
    path: '/join', name: 'Join', component: _import('join'),
    meta: { title: '报名' }
  },
  // 搜索结果
  {
    path: '/searchResult', name: 'SearchResult', component: _import('searchResult'),
    meta: { title: '搜索结果' }
  }
]
