// 安装所需vant插件
import Vue from 'vue'

import {
  Button,
  Tag,
  Toast,
  Dialog,
  Tabbar,
  TabbarItem,
  Icon,
  Form,
  Field,
  Sticky,
  Popup,
  Search,
  DropdownMenu,
  DropdownItem,
  Col, Row,
  List,
  Empty,
  Picker,
  Step,
  Steps,
  DatetimePicker,
  Uploader,
  Loading,
  Image,
  ImagePreview,
  RadioGroup,
  Radio,
  Area,
  Checkbox, CheckboxGroup,
  Swipe, SwipeItem,
  Tab, Tabs,
  PullRefresh
} from 'vant'

Vue.use(Button).use(Tag)
  .use(Toast).use(Dialog)
  .use(Tabbar).use(TabbarItem)
  .use(Icon).use(Popup)
  .use(Form).use(Field).use(Sticky)
  .use(Search)
  .use(DropdownMenu).use(DropdownItem)
  .use(Col).use(Row)
  .use(List)
  .use(DatetimePicker).use(Picker)
  .use(Empty).use(Loading)
  .use(Form).use(Field).use(Sticky).use(Picker)
  .use(Step).use(Steps).use(DatetimePicker).use(Uploader).use(Image).use(ImagePreview)
  .use(RadioGroup).use(Radio).use(Area).use(Checkbox).use(CheckboxGroup)
  .use(Swipe).use(SwipeItem)
  .use(Tab).use(Tabs).use(PullRefresh)

