<template>
  <div id="app">
    <router-view class="page_content" />
    <van-tabbar class="self-tabbar" v-model="active">
      <van-tabbar-item name="index" :to="{path:'/index'}">
        <span>首页</span>
        <template #icon="props">
          <i class="icon-bar f1" :class="{'active':props.active}"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="learn" :to="{path:'/organ'}">
        <span>学会</span>
        <template #icon="props">
          <i class="icon-bar f2" :class="{'active':props.active}"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="mine" :to="{path:'/mine'}">
        <span>我的</span>
        <template #icon="props">
          <i class="icon-bar f3" :class="{'active':props.active}"></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import util from "@/libs/util.js";
import {Dialog} from "vant";
import cookies from '@/libs/util.cookies'
const Config = require('../configs/main.config')

export default {
  name: "App",
  data() {
    return {
      active:'index',
      code: ''
    };
  },
  created() {
    util.title("中国医促会妇产分会-蒲公英医学");
  },
  async mounted() {
    const code = this.$untils.getUrlKey('code');
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    console.log('用户信息home', userinfo)
    let that = this
    if (!code && !userinfo) {
      Dialog({ message: '正在授权登录' });
      this.qyLogin()
    } else if (!code && userinfo) {
      this.$api.GET_LOGIN_POST({}).then(res => {
        if (res.code && (res.code == 1102 || res.code == 1101)) {
            Dialog({ message: '正在授权登录' });
            that.qyLogin()
        }
      })
    } else if(code && !userinfo) {
      this.code = code;
      this.login()
    }
  },
  methods: {
    ...mapMutations("header", ["setLabelText"]),
    //微信
    qyLogin() {
      const corp_id = Config.corp_id;
      const agent_id = Config.agent_id;
      const redirect_uri = encodeURI(Config.site_base + Config.app_path);
      const login_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corp_id}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_privateinfo&state=STATE&agentid=${agent_id}#wechat_redirect`
      window.location = login_url;
    },
    //登录
    login() {
      this.$api.LOGIN_POST({'code': this.code}).then(res => {
        if (res.code && res.code == 500) {
          localStorage.removeItem('userinfo')  // 推荐使用
          cookies.set('mydoctor_token', '');
          Dialog({ message: res.msg });
        } else {
          Dialog({ message: '授权登录成功' });
          cookies.set('mydoctor_token', res.token);
          localStorage.setItem("userinfo", JSON.stringify(res));
        }
      })
    }
  },
};
</script>

<style lang="less">
/* 项目通用自定义样式 */
@import "~@/assets/style/index.less";
.page_content{
  min-height:calc(100vh - 80px);
  padding-bottom:80px;
}
</style>
<style lang="less" scoped>

  .icon-bar{
    display:block;
    width:28px;
    height:28px;
    background:url(./assets/images/common/f1.png) no-repeat;
    background-size:28px auto;
    &.active{
      background-image:url(./assets/images/common/f1-a.png);
    }
  }

  .icon-bar.f2{
    background-image:url(./assets/images/common/f2.png);
    &.active{
      background-image:url(./assets/images/common/f2-a.png);
    }
  }

  .icon-bar.f3{
    background-image:url(./assets/images/common/f3.png);
    &.active{
      background-image:url(./assets/images/common/f3-a.png);
    }
  }

  .self-tabbar{
    background:#eee;
    padding:6px 0;
    .van-tabbar-item--active{
      background:#eee;
    }
  }
  
</style>
