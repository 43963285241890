import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import util from '@/libs/util.js'

// 路由数据
import routes from './routes'

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes,
  mode: 'history'
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  next()
})

router.afterEach(to => {
  // 设置页面标题
  store.dispatch('history/pushHistory', to.name)
  util.title(to.meta.title)
})

export default router
