module.exports = {
    // 企业信息
    corp_id: "ww8c470d0a6412daab",
    
    // 应用信息
    agent_id: "1000007",
    app_secret:"C_D-eySpEnpzs4wAkH7GzXJdLhViUZUxMUeekv-gI_g",

    // 网站根目录 URL
    site_base:'https://mydoctor.rimi.com.cn',

    // 授权后跳转 URL
    app_path:'/index',
}