import dayjs from 'dayjs'
import store from '@/store'

const filter = {
    price (value) {
        if (value) {
            value = Number(value)
            return '¥' + (value / 100).toFixed(2)
        }
        return '¥0.00'
    },
    formatMoney (value) {
        if (value) {
            value = Number(value)
            return (value / 100).toFixed(2)
        }
        return '0'
    },
    formatDate (value) {
        if(value){
            return dayjs(value).format('YYYYMMDD')
        }else{
            return  ''
        }

    },
    formatshortDate (value) {
        if(value){
            return dayjs(value).format('YYYY年MM月DD日')
        }else{
            return  ''
        }

    },
    formatshortDate_ (value) {
        if(value){
            return dayjs(value).format('YYYY-MM')
        }else{
            return  ''
        }

    },
    formatDate_ (value) {
        if(value){
            return dayjs(value).format('YYYY-MM-DD')
        }else{
            return  ''
        }

    },
    formatDateTime (value) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    educationText(value){
      return store.getters['dic/getEducationText'](value)
    },
    workTypeText(value){
      return store.getters['dic/getWorkTypeText'](value)
    },

}
export default filter
